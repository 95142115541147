import PersonIcon from '@mui/icons-material/Person';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/styles';
import axios from 'axios';
import { GetServerSidePropsContext } from 'next';
import { useTranslations } from 'next-intl';
import { NextRouter, useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import qs from 'qs';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Footer from '../components/footer/footer';
import Logo from '../public/images/ChristaudLogo.svg';
import { resolvedTokenWithRolesActionCreator } from '../redux/actions/resourceActionCreator';
import { setLoadingStateToAction } from '../redux/actions/uiActionCreator';
import { loginReqIdAction, resolvedTokenAction } from '../redux/actions/userActionCreator';
import { GlobalState } from '../redux/reducers';
import { TokenData } from '../redux/reducers/userReducer';
import { getToken, getTokenData } from '../redux/selectors/user';
import getRequestIdGenerator from '../services/requestIdGenerator';
import { REDIRECT_PAGE, TOKEN_WITH_ROLES } from '../services/utils/CONST';
import { getLocale } from '../services/utils/get-translates';
import { routeWithParamAs } from '../services/utils/route';

const RootGrid = styled(Grid)({
    paddingLeft: 10,
    paddingRight: 10,
    overflow: 'scroll',
});

const VisibilityIconButton = styled(IconButton)({
    padding: 0,
});

const TitleTypography = styled(Typography)({
    marginTop: 50,
    marginBottom: 50,
    fontWeight: 900,
    fontStyle: 'italic',
    fontSize: 18,
    textTransform: 'uppercase',
});

const CustomButton = styled(Button)({
    marginBottom: 30,
});

const restHeight = 50;
const CORE_API = process.env.NEXT_PUBLIC_CORE_API;

interface Props {}

const Login = ({}: Props) => {
    const tCommon = useTranslations('common');
    const dispatch = useDispatch();
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [showPassword, setShowPassword] = useState<any>(false);
    const router: NextRouter = useRouter();
    const { enqueueSnackbar } = useSnackbar();
    const [loadingState, setLoadingState] = useState(false);
    const tokenData: TokenData | null = useSelector((state: GlobalState) => getTokenData(state));
    const token: string | null = useSelector((state: GlobalState) => getToken(state));
    const [divHeight, setDivHeight] = useState(400);

    useEffect(() => {
        setDivHeight(window.innerHeight - restHeight);
        window.addEventListener('resize', () => setDivHeight(window.innerHeight - restHeight));
    }, []);

    const handleChangeEmail = ({ target: { value } }: any) => {
        setEmail(value);
    };

    const handleChangePassword = ({ target: { value } }: any) => {
        setPassword(value);
    };

    const getTokenWithRolesRequest = (token: string) => {
        dispatch(setLoadingStateToAction.fn(true));
        axios
            .get(`${CORE_API}${TOKEN_WITH_ROLES}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(({ data }) => {
                dispatch(resolvedTokenWithRolesActionCreator.fn(data.token));
                dispatch(setLoadingStateToAction.fn(false));
                routeWithParamAs(router, `${REDIRECT_PAGE}?${qs.stringify(router.query)}`);
            });
    };

    const handleLogin = () => {
        dispatch(setLoadingStateToAction.fn(true));
        dispatch(
            loginReqIdAction.fn(getRequestIdGenerator().gen(), email, password, {
                // @ts-ignore
                onSuccess: (payload: { token: string; refresh_token: string }) => {
                    dispatch(resolvedTokenAction.fn(payload.token, payload.refresh_token));
                    getTokenWithRolesRequest(payload.token);
                },
                onFailure: (...args) => {
                    enqueueSnackbar(tCommon('errorMessages.wrongCredentials'), {
                        variant: 'error',
                    });
                    dispatch(setLoadingStateToAction.fn(false));
                },
            })
        );
    };

    const handleClickShowPassword = () => {
        setShowPassword((previousValue: boolean) => setShowPassword(!previousValue));
    };

    useEffect(() => {
        if (token && tokenData && !tokenData.payload.is_anonymous) {
            getTokenWithRolesRequest(token);
        }
    }, [token, tokenData]);

    return (
        <Fragment>
            <RootGrid
                container
                direction={'column'}
                alignItems={'stretch'}
                style={{ height: divHeight }}
                wrap={'nowrap'}
            >
                <Grid item>
                    <Grid container direction={'column'} alignItems={'center'}>
                        <Grid item>
                            <TitleTypography>{tCommon('texts.login')}</TitleTypography>
                        </Grid>

                        <Grid item>
                            <SvgIcon
                                component={Logo}
                                viewBox='0 0 151 45'
                                style={{ width: 300, height: 45, marginBottom: 60 }}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item>
                    <Grid container direction={'column'} alignItems={'stretch'} spacing={3}>
                        <Grid item>
                            <FormControl fullWidth variant={'standard'}>
                                <InputLabel>{tCommon('texts.email')}</InputLabel>
                                <Input
                                    value={email}
                                    onChange={handleChangeEmail}
                                    endAdornment={
                                        <InputAdornment position='end'>
                                            <PersonIcon />
                                        </InputAdornment>
                                    }
                                    autoFocus
                                    onKeyUp={(event) => {
                                        if (event.key === 'Enter') {
                                            handleLogin();
                                        }
                                    }}
                                    data-test-sc='username'
                                />
                            </FormControl>
                        </Grid>

                        <Grid item>
                            <FormControl fullWidth variant={'standard'}>
                                <InputLabel>{tCommon('texts.password')}</InputLabel>
                                <Input
                                    value={password}
                                    type={showPassword ? 'text' : 'password'}
                                    onChange={handleChangePassword}
                                    endAdornment={
                                        <InputAdornment position='end'>
                                            <VisibilityIconButton onClick={handleClickShowPassword}>
                                                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </VisibilityIconButton>
                                        </InputAdornment>
                                    }
                                    onKeyUp={(event) => {
                                        if (event.key === 'Enter') {
                                            handleLogin();
                                        }
                                    }}
                                    data-test-sc='password'
                                />
                            </FormControl>
                        </Grid>

                        <Grid item>
                            <CustomButton
                                disabled={email.length === 0 || password.length === 0}
                                data-test-sc='loginButton'
                                variant={'contained'}
                                color={'primary'}
                                onClick={handleLogin}
                                onKeyPress={(event) => {
                                    if (event.key === 'Enter') {
                                        handleLogin();
                                    }
                                }}
                            >
                                {tCommon('buttons.connection')}
                            </CustomButton>
                        </Grid>
                    </Grid>
                </Grid>

                <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loadingState}>
                    <CircularProgress color='primary' />
                </Backdrop>
            </RootGrid>

            <Footer />
        </Fragment>
    );
};

export default Login;

export const getServerSideProps = async (context: GetServerSidePropsContext) => ({
    props: {
        messages: {
            ...require(`../messages/common/${getLocale(context)}.json`),
        },
    },
});
